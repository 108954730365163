/*
 * @Author: duck
 * @Date: 2023-01-31 16:39:12
 * @LastEditors: duck
 * @LastEditTime: 2023-08-09 11:49:04
 * @Description: file content
 */
const SOCIAL_TYPES = {
	1: 'QQ',
	2: '微信',
	3: 'IMChat',
	4: '客服线',
	5: '代理线'
}
// 采用新打电话方式的平台
const NEW_CALL_MODE_PLATFORM = [
	'00001',
	'00004',
	'00007',
	'00008',
	'00021',
	'00029',
	'00030',
	'00031',
	'00033',
	'00034',
	'00035',
	'00036',
	'00038',
	'00039',
	'00040',
	'00041',
	'00042',
	'00043',
	'00044',
	'00045',
	'00046',
	'00047',
	'00015',
	'00048'
]
// 电销平台
const DX_PLATFORM = ['00015', '00032', '00033', '00034', '00039', '00040', '00041', '00043', '00045', '00046','00048']
// 会员资料进入默认不展示数据,必须要搜索
const SEARCH_PLATFORM = ['00033']
//带有群呼的平台
const GROUP_CALL = ['00034', '00041', '00015', '00008', '00007', '00004', '00030', '00021', '00039', '00043', '00040', '00047', '00048']
//展示注册搜索的平台
const SHOW_QUERY_REGISTER=['00034','00038','00044', '00047','00001','00048']
//展示代理状态的平台
const SHOW_QUERY_AGENT_STATUS=['00044','00001']

//展示导入批次的平台
const SHOW_QUERY_CRMTASKID=['00034', '00047']
//india平台
const INDIA_PLATFORM=['00001','00034','00038','00044', '00047','00048']
const SMS_TEMPLATE = [
	// {
	// 	label: '模板1',
	// 	value: 61198,
	// 	content: '[TURFTE]Come here, you earn not only money, but also your true self. $_INPUT',
	// 	defaultText: 'https://download.sikwinchat.com/'
	// },
	// {
	// 	label: '模板2',
	// 	value: 630291,
	// 	content: 'Join us for a gaming experience that puts honesty and fairness first. $_INPUT INDLAZA Pvt Ltd.',
	// 	defaultText: 'https://download.sikwinchat.com/'
	// },
	// {
	// 	label: '模板2',
	// 	value: '627777',
	// 	// content: 'Indian real cash games. Including rummy, wingo, abfun,etc. Registed get $_INPUT rs: {SIKWIN}',
	// 	// content: "Download India's sexiest casino on your phone and get Rs. $_INPUT upon registration! {SIKWIN} - Crepoly",
	// 	// content:
	// 	// 	'Dear (TenPatti Cash) player, congratulations on being chosen as our VIP customer / Rs100 is now open for you has reported to your account at $_INPUT bonus',
	// 	// content: "Download India's sexiest casino on your phone and get Rs. $_INPUT upon registration! {SIKWIN} - Crepoly",
	// 	// content: '(Color Rummy) Your $_INPUT has been verified, please login and check.',
	// 	content: 'sikwin lifted the ban on online rummy. Get sikwin bonus code sikwin, go back to Color Rummy and download app $_INPUT --- Color Rummy',

	// 	defaultText: 'https://download.sikwinchat.com'
	// }
	{
		label: '模板2',
		value: '627777',
		// content: 'Indian real cash games. Including rummy, wingo, abfun,etc. Registed get $_INPUT rs: {SIKWIN}',
		// content: "Download India's sexiest casino on your phone and get Rs. $_INPUT upon registration! {SIKWIN} - Crepoly",
		// content:
		// 	'Dear (TenPatti Cash) player, congratulations on being chosen as our VIP customer / Rs100 is now open for you has reported to your account at $_INPUT bonus',
		// content: "Download India's sexiest casino on your phone and get Rs. $_INPUT upon registration! {SIKWIN} - Crepoly",
		// content: '(Color Rummy) Your $_INPUT has been verified, please login and check.',
		content: '(Color Rummy) Your $_INPUT has been verified, please login and check.',

		defaultText: 'https://download.sikwinchat.com'
	},
	{
		label: '空白模板',
		value: '61198',
		content: '$_INPUT',
		defaultText: 'https://download.sikwinchat.com/'
	}
]
// 修改手机号对应的前端页面域名
const CHANGE_NUMBER_DOMAIN = {
	'00015': 'https://wb.updatesj.com', //测试 暂无测试地址 默认万博了
	'00004': 'https://wb.updatesj.com', //万博
	'00008': 'https://yt.updatesj.com', //亚投
	'00007': 'https://yg.updatesj.com', //亚冠
	'00021': 'https://yc.updatesj.com', //亿彩
	'00030': 'https://htxpj.updatesj.com', //宏图
	'00031': 'https://ty.updatesj.com', //51
	'00029': 'https://pj1.updatesj.com', //新一
	'00042': 'https://hth.updatesj.com' //华体会
}
export { SOCIAL_TYPES, NEW_CALL_MODE_PLATFORM, DX_PLATFORM, SEARCH_PLATFORM, GROUP_CALL, SMS_TEMPLATE, CHANGE_NUMBER_DOMAIN,SHOW_QUERY_REGISTER,SHOW_QUERY_AGENT_STATUS,SHOW_QUERY_CRMTASKID,INDIA_PLATFORM}
